// paper & background
$paper: #ffffff;

// primary
$primaryLight: #e3f2fd;
$primaryMain: #2196f3;
$primaryDark: #1e88e5;
$primary200: #90caf9;
$primary800: #1565c0;

// secondary
$secondaryLight: #ede7f6;
$secondaryMain: #673ab7;
$secondaryDark: #5e35b1;
$secondary200: #b39ddb;
$secondary800: #4527a0;

// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #00c853;

// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;
$orange400: #FC9460;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

//blue
$blue50: #e3f2fd;
$blue150: #CBE1F1;
$blue175: #87b4d6;
$blue300: #128EED;
$blue400: #2F89C8;
$blue500: #163DC7;
$blue600: #215273;
$blue700: #03416B;
$blue750: #003761;

//green
$green100: #6DFFCB;
$green200: #12D888;
$green400: #2EBA88;
$green450: #16C79A;
$green500: #108C60;
$green600: #0D7223;

// grey
$grey50: #fafafa;
$grey60: #fbfbfb;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey325: #D2D2D2;
$grey350: #CCCCCC;
$grey400: #BDBBBB;
$grey500: #9e9e9e;
$grey550: #888888;
$grey600: #757575;
$grey650: #746F6F;
$grey700: #616161;
$grey800: #535353;
$grey900: #212121;

//red
$red400: #FF5A3B;
$red700: #FF4155;
$red750: #F40505;
$red800: #D32030;

//yellow
$yellow700: #FFBF00;

//purple
$purple700: #7C007C;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #e3f2fd;
$darkPrimaryMain: #2196f3;
$darkPrimaryDark: #1e88e5;
$darkPrimary200: #90caf9;
$darkPrimary800: #1565c0;

// secondary dark
$darkSecondaryLight: #d1c4e9;
$darkSecondaryMain: #7c4dff;
$darkSecondaryDark: #651fff;
$darkSecondary200: #b39ddb;
$darkSecondary800: #6200ea;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;

// ==============================|| JAVASCRIPT ||============================== //

:export {
    // paper & background
    paper: $paper;

    // primary
    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;
    orange400: $orange400;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    //blue
    blue50: $blue50;
    blue150: $blue150;
    blue175: $blue175;
    blue300: $blue300;
    blue400: $blue400;
    blue500: $blue500;
    blue600: $blue600;
    blue700: $blue700;
    blue750: $blue750;

    //green
    green100: $green100;
    green200: $green200;
    green400: $green400;
    green450: $green450;
    green500: $green500;
    green600: $green600;

    // grey
    grey50: $grey50;
    grey60: $grey60;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey325: $grey325;
    grey350: $grey350;
    grey400: $grey400;
    grey500: $grey500;
    grey550: $grey550;
    grey600: $grey600;
    grey650: $grey650;
    grey700: $grey700;
    grey800: $grey800;
    grey900: $grey900;

    //red
    red400: $red400;
    red700: $red700;
    red750: $red750;
    red800: $red800;

    //yellow
    yellow700: $yellow700;

    //purple
    purple700: $purple700;

    // ==============================|| DARK THEME VARIANTS ||============================== //

    // paper & background
    darkPaper: $darkPaper;
    darkBackground: $darkBackground;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    darkTextTitle: $darkTextTitle;
    darkTextPrimary: $darkTextPrimary;
    darkTextSecondary: $darkTextSecondary;

    // primary dark
    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain: $darkPrimaryMain;
    darkPrimaryDark: $darkPrimaryDark;
    darkPrimary200: $darkPrimary200;
    darkPrimary800: $darkPrimary800;

    // secondary dark
    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain: $darkSecondaryMain;
    darkSecondaryDark: $darkSecondaryDark;
    darkSecondary200: $darkSecondary200;
    darkSecondary800: $darkSecondary800;
}
